import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Button, Spinner, Card } from 'react-bootstrap';
import InlineLoading from '../../components/loaders/InlineLoading';
import insertTextAtCursor from 'insert-text-at-cursor';
import apiRequest from 'utilities/api';

@inject('authStore')
@observer class RoboCallingScripts extends Component {
	state = {
		loading: true,
		scriptSelected: false,
		selectedScript: {},
		scriptValueModified: false,
		loadingChange: false,
	}
	componentDidMount = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getRoboScripts', token });
		this.setState({ loading: false, scripts: data.data });
	}
	fetchAllData = async () => {
		const { token } = this.props.authStore.values;
		const data = await apiRequest({ endpoint: 'v3/myrossware/getRoboScripts', token });
		this.setState({ scripts: data.data });
	}
	renderScriptsToSelect = () => { // PHYSICALLY RENDER SCRIPTS TO SELECT ELEMENT
		const scriptTypes = { 1: '1: Initial greeting', 2: '2: Invitation to confirm', 3: '3: Acknowledge confirmation', 4: '4: Acknowledge cancellation' };
		const { scripts } = this.state;
		return ((scripts || []).map((script, index) => {
			return (
				<option key={script.id} value={index}>{scriptTypes[script.ScriptType]}</option>
			);
		}));
	}
	onScriptSelectChange = (event) => { // ON CHANGE OF SELECT ELEMENT
		const { scripts } = this.state;
		const indexOfSelection = event.target.value;
		this.setState({ selectedScript: scripts[indexOfSelection], scriptValueModified: false, scriptSelected: true });
		document.getElementById('myTextArea').value = scripts[indexOfSelection].Script;
	}
	onTextAreaChange = () => {
		const textAreaValue = document.getElementById('myTextArea').value;
		this.setState({ scriptValueModified: !(textAreaValue === this.state.selectedScript.Script), currentTextAreaValue: textAreaValue });
	}
	insertScriptTag = (tag) => { // INSERT SPEAKABLE SCRIPT TAGS INTO TEXTAREA AT CURSOR
		if (this.state.scriptSelected) {
			insertTextAtCursor(document.getElementById('myTextArea'), tag);
		}
	}
	onSaveButtonClick = async () => { // TRIGGERED BY 'SAVE' BUTTON CLICK
		this.setState({ loadingChange: true });
		const { token } = this.props.authStore.values;
		const { id: record_id } = this.state.selectedScript;
		const { currentTextAreaValue } = this.state;
		await apiRequest({ endpoint: 'v3/myrossware/setRoboScript', parameters: { record_id: record_id, script: currentTextAreaValue }, token });
		this.fetchAllData();
		this.setState({ loadingChange: false, scriptValueModified: false });
		this.state.selectedScript.Script = currentTextAreaValue;
	}
	render() {
		if (this.state.loading) { return (<Card><Card.Body><InlineLoading /></Card.Body></Card>); } else {
			return (
				<div>
					<h1 className="page-title">Settings</h1>
					<div className="card">
						<div className="card-body">
							<h3>Scripts</h3>
							<div className="grid-2-col border-bottom mb-4 pb-4">
								<div>
									<div className="myRWRoboCallingRecognizedVariables">
										<span>The below items are recognized by our machinery and play through the phone to your caller or callee. Click a bracketed [tag] to insert it into your script.</span>
										<div className="border-bottom mb-4 mt-4"></div>
										<ul>
											<li><span onClick={() => this.insertScriptTag('[BsNm]')} className="myRWClickableRoboTag">[BsNm]</span> -- Your business&apos;s name.</li>
											<li><span onClick={() => this.insertScriptTag('[BsPhn]')} className="myRWClickableRoboTag">[BsPhn]</span> -- Your business&apos;s phone number.</li>
											<li><span onClick={() => this.insertScriptTag('[ConfirmNumber]')} className="myRWClickableRoboTag">[ConfirmNumber]</span> -- This is the phone number you picked when signing up for RoboCalling, it is the number actually associated with all robocalling activity behind the scenes and the number your clients can call back for automated confirmations.</li>
											<li><span onClick={() => this.insertScriptTag('[TimeFrame]')} className="myRWClickableRoboTag">[TimeFrame]</span> -- The appointment time frame.</li>
											<li><span onClick={() => this.insertScriptTag('[AppmntDesc]')} className="myRWClickableRoboTag">[AppmntDesc]</span> -- Description of the appointment, i.e. &quot;Monday, January 1st&quot;.</li>
											<li><span onClick={() => this.insertScriptTag('[CstmrNm]')} className="myRWClickableRoboTag">[CstmrNm]</span> -- The customer&apos;s name.</li>
											<li><span onClick={() => this.insertScriptTag('[Phonetic]')} className="myRWClickableRoboTag">[Phonetic]</span> -- Some business names the system has a hard time &quot;reading&quot;, so if your business name is one of those, you can use this and enter a phonetic spelling on the settings page.</li>
											<li><span onClick={() => this.insertScriptTag('[PAUSE=1]')} className="myRWClickableRoboTag">[PAUSE=1]</span> -- This will put in a pause for the number of seconds specified. Case sensitive. [PAUSE] tags must be on their own line.</li>
										</ul>
									</div>
								</div>
								<div>
									<Form.Control as="select" defaultValue="defaultSelected" className="mb-3" onChange={this.onScriptSelectChange} disabled={this.state.loadingChange} >
										<option disabled key="defaultSelect" value="defaultSelected">Select Script</option>
										{this.renderScriptsToSelect()}
									</Form.Control>
									<Form.Control as="textarea" rows="10" className="mb-3 myRWTextArea" defaultValue={this.state.selectedScript.Script} id="myTextArea" onChange={this.onTextAreaChange} disabled={!this.state.scriptSelected || this.state.loadingChange} />
									<Button variant="primary" type="submit" onClick={this.onSaveButtonClick} disabled={!this.state.scriptValueModified || this.state.loadingChange} >
										{this.state.loadingChange &&
											<Fragment>
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												<span>&nbsp;&nbsp;</span>
											</Fragment>
										}
										Save
									</Button>
								</div>
							</div>
						</div>
					</div>

					<div className="card d-none">
						<div className="card-body">
							<div className="grid-2-col">
								<div>
									<h3>Phoenetic name</h3>
									<p>Some businesses have names which robocall voices are not able to pronounce properly. If this is the case, you can use this feature to force the correct pronunciation of your business name.</p>
								</div>
								<div>
									<Form.Control className="mb-3"></Form.Control>
									<Button disabled>Save</Button>
								</div>
							</div>
						</div>
					</div>

				</div>
			);
		}
	}
}

export default RoboCallingScripts;
